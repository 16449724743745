.leaflet-container {
  height: 100%;
  width: 100%;
  .info {
    font-family: Roboto, sans-serif;
    padding: 0.5*$spacer $spacer;

    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);;
  }
}