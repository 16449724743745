.dashboard-layout {
  height: 100%;
  display: flex;
  .app-bar {
    margin-left: $drawer-width;
  }
  .MuiContainer-root {
    padding-top: 5 * $spacer;
  }

  @media(min-width: map_get($grid-breakpoints, "lg")) {
    .app-bar {
      width: calc(100% - #{$drawer-width})
    }
    .menu-button {
      display: none;
    }
  }
}