#ensemble-north-america {
  .MuiFormControl-root {
    min-width: 120px;
  }
  .selects-container, hr {
    margin-top: $spacer;
    margin-bottom: $spacer;

  }
  h4 {
    text-align: center;
  }
}