#reanalysis-cera-20c {
  margin-bottom: 3rem;
  width: 100%;
  .download-button {
    width: 100%;
    height: 3rem;
    svg {
      margin-left: $spacer * 0.5;
    }
  }

  h5 {
    margin-bottom: $spacer * 0.5;
  }

  @media(min-width: map_get($grid-breakpoints, "lg")) {
    form {
      display: flex;
      > * {
        margin-right: 1rem;
      }
    }
    .download-button {
      width: auto;
      height: auto;
      position: fixed;
      bottom: 40px;
      right: 40px;
      box-shadow: 0 2px 3px #999;
      border-radius: 4px;
    }
  }
}
