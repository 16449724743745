// Bootstrap variables
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";

// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap";

// Core variables and mixins
@import "utils/variables";
@import "utils/mixins";

// Global CSS
@import "base/global";

// Components
@import "components/leaflet-map";
@import "components/react-datepicker";
@import "components/buttons";
@import "components/checkboxes";
@import "components/label-input";
@import "components/inputs/years.input";
@import "components/inputs/date.input";
@import "components/responsive-drawer";
@import "components/form";
@import "components/highmap";

// Layouts
@import "layouts/dashboard.layout";
// Views
@import "views/cera-20c.view";
@import "views/north-america.view";
@import "views/projections-canada.views";




