.responsive-drawer {
  @media(min-width: map_get($grid-breakpoints, "lg")) {
    width: $drawer-width;
    flex-shrink: 0;
  }
}
.MuiPaper-root {
  a:hover {
    color: $default-text-color;
  }
}
.drawer-paper {
  width: $drawer-width;
}

