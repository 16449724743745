// Global styling for this template
html, body, #root {
  height: 100%;
}
body {
  @include body-font;
}
p {
  line-height: 1.75;
  text-align: justify;
}

a {
  color: $primary;
  &:hover {
    color: darken($primary, 10%);
  }
}

.text-primary {
  color: $primary !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  @include heading-font;
}



