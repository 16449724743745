#projections-canada {
  .MuiFormControl-root {
    min-width: 120px;
  }
  .selects-container, hr {
    margin-top: $spacer;
    margin-bottom: $spacer;

  }
  .MuiCard-root {
    height: 100%;
  }
  .MuiGrid-container {
    margin-bottom: 2*$spacer;
  }
}