// Variables
$drawer-width: 240px;
$grid-breakpoints: (
        sm: 600px,
        md: 960px,
        lg: 1280px,
        xl: 1920px
);
// Restated Bootstrap Variables

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$default-text-color: rgba(0, 0, 0, 0.87);

$navbar-color: #e7f0ff !default;

$section-pair: white !default;
$section-impair: #f6f8ff !default;

$primary: #316ffe !default;
$secondary: #b4c5e4 !important;
$logo-color: #009FE3 !default;
$shadow: #373f51 !default;

$platform-background: #f8f9fc !default;
