.react-datepicker__input-container {
  input {
    padding: 14.5px 14px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
  input:hover {
    border: 1px solid black;
  }
  input:focus {
    border: 2px solid darkblue;
  }
}